@use 'sass:map';
@use '@angular/material' as mat;
@use './assets/theme/theme.scss';
@import './assets/theme/colors.scss';
@import './assets/scss/_variables';

/** Theme **/
@include mat.core();
html {
  @include mat.core-theme(theme.$light-theme);
  @include mat.all-component-themes(theme.$light-theme);

  --mdc-outlined-button-container-shape: 4px;

  --mat-menu-container-color: var(--neutral-100);
  --mat-autocomplete-background-color: var(--neutral-100);

  --mat-select-panel-background-color: var(--neutral-100);
  --mat-option-selected-state-layer-color: #{map.get(map.get(theme.$palettes, primary), 98)};
  --mat-option-hover-state-layer-color: #{map.get(map.get(theme.$palettes, secondary), 99)};
  --mat-option-focus-state-layer-color: #{map.get(map.get(theme.$palettes, secondary), 98)};
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--neutral-100);

  --mat-menu-item-hover-state-layer-color: #{map.get(map.get(theme.$palettes, secondary), 99)};
  --mat-menu-item-focus-state-layer-color: #{map.get(map.get(theme.$palettes, secondary), 98)};
}
/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html,
body {
  background: var(--background);
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

.header-logo {
  height: 1.5rem;
  vertical-align: middle;
}

.bay-transit .header-logo {
  height: 2rem;
}

.container {
  padding: 2rem 1rem;
  min-height: calc(100vh - env(safe-area-inset-top) - 3.5rem);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto min-content;
}

.default-background {
  background-image: url('./assets/img/watermark.svg');
  background-position: center bottom;
  background-size: 101vw;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.star-previous-location {
  background: transparent;
  background-image: url('./assets/img/star.png');
  background-size: 1rem;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  color: var(--gold);
  font-size: 16px;
  vertical-align: baseline;
}

input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--overlay);
  -webkit-box-shadow: 0 0 1px var(--shadow-color);
}

.mat-option,
.mat-option-text {
  display: inline-block;
  flex-grow: 1;
  overflow-x: scroll;
}

.create-ride-background {
  background-image: url('./assets/img/add-background.svg');
  background-position: 97% bottom;
  background-size: 22rem;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.bay-transit .create-ride-background {
  background-size: contain;
  background-position: center bottom;
}

@media (min-width: $medium) {
  .default-background {
    background-image: url('./assets/img/watermark-medium.svg');
    background-position: 97% bottom;
    background-size: 300px;
  }
  .bay-transit .default-background {
    background-size: contain;
    background-position: center bottom;
  }
}

@media (min-width: $large) {
  .default-background {
    background-position: 97% bottom;
    background-size: 300px;
  }
}

h1 {
  font-size: 1.5rem;
  color: var(--primary);
  line-height: 2.25rem;
  font-weight: 400;
  // margin: 2rem 0 1rem;
  margin: 0rem 0 1rem;
}

h2,
legend {
  font-size: 1.25rem;
  color: var(--primary);
  line-height: 1.875rem;
  font-weight: 400;
  margin: 1rem 0;
}

p {
  margin-top: 0;
}

a {
  color: var(--links);
}

a:link,
a:visited {
  color: var(--links);
  text-decoration: none;
}

p a, .mat-checkbox a { text-decoration: underline; }

a:hover,
a:focus {
  color: var(--links);
  text-decoration: underline;
}

input,
textarea,
input.mat-input-element,
.input {
  font-family: 'Helvetica Neue', sans-serif;
  border: 1px solid var(--border-80);
  border-radius: 4px;
  caret-color: var(--neutral-60);
  display: block;
  font-size: 1rem;
  line-height: 2.5rem;
  margin: 0.25rem 0;
  padding: 0 0.5rem;
  width: 100%;
  height: 2.5rem;

  &[disabled] {
    background-color: var(--background-neutral);
    border-color: var(--background-neutral);

    &:hover {
      border-color: transparent;
    }
  }

  &:invalid,
  &.ng-invalid,
  &.invalid,
  &[invalid]  {
    border-left: solid 5px var(--warn-official);
  }

  &.ng-invalid.ng-touched,
  &.invalid.ng-touched,
  &[invalid] {
    background-color: var(--background-warn);
    border-color: var(--warn-official);
  }

  &:hover,
  &:focus {
    border: dashed 1px var(--border-80);
    outline-offset: 2px;
    outline: none;
    &:invalid,
    &.ng-invalid,
    &.invalid,
    &[invalid]  {
      border-left: solid 5px var(--warn-official);
    }
  }

  &::placeholder {
    color: var(--neutral-60)-grey;
  }
}

.table::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--overlay);
  -webkit-box-shadow: 0 0 1px var(--shadow-color);
}

.mat-mdc-select {
  &:invalid,
  &.ng-invalid,
  &.invalid,
  &[invalid]  {
    .mat-select-trigger {
      border-left: solid 5px var(--warn-official);
    }
  }
}
.mat-mdc-select-value {
  padding-left: 1ch;
  padding-right: 3ch;
}
.mat-mdc-select-arrow { display: none }

.select,
.mat-mdc-select-trigger {
  background: transparent;
  background-color: var(--neutral-100);
  background-image: url('./assets/icons/inverted-caret.svg');
  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: center right;
  border: 1px solid var(--border-80);
  border-radius: 4px;
  display: block;
  height: 2.5rem;
  margin: 0.25rem 0;
  overflow: hidden;
  height: 2.5rem;

  &:hover,
  &:focus {
    border: dashed 1px var(--border-80);
    outline-offset: 2px;
    outline: none;
  }

  select {
    background: transparent;
    border: none;
    font-size: 1rem;
    height: 2.5rem;
    line-height: 2.5rem;
    width: calc(100% + 1rem);
    outline: none;
  }
  &.mat-select-trigger { margin:0 }

  &[disabled] {
    background-color: var(--background-neutral);
    border-color: var(--background-neutral);
  }
}
.mat-select {
  margin: 0.25rem 0;
}

.mat-pseudo-checkbox-checked {
  background: var(--primary);
}

.mat-mdc-form-field-error {
  font-size: 0.875rem;
  letter-spacing: initial;
}

.mat-mdc-form-field-error,
.error {
  color: var(--warn-compliant);
}

.mat-mdc-form-field-error:before {
  bottom: -0.125rem;
  content: url('./assets/icons/error.svg');
  display: inline-block;
  position: relative;
  margin-right: 0.25rem;
  width: 1rem;
}

.mat-hint {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
}

.hint {
  font-style: italic;
  font-size: 0.75rem;
  font-weight: 400;
}

label,
.label,
legend,
dt {
  color: var(--neutral-40);
  display: block;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0625rem;
  line-height: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  &.mat-checkbox-layout {
    width: auto;
  }
}

label.required,
legend.required,
label:has([aria-required="true"], [required]),
h3.required {
  &:before {
    content: '* ';
    color: var(--warn-official);
    font-weight: bold;
  }
}

button,
button.mat-mdc-button,
button.mat-mdc-flat-button,
button.mat-mdc-icon-button,
button.mat-mdc-stroked-button,
a.button,
a.mat-mdc-button,
a.mat-button,
a.mat-flat-button,
a.mat-mdc-icon-button,
a.mat-stroked-button {
  --mdc-text-button-label-text-color: var(--neutral-100);
  background: var(--primary);
  border: solid 1px var(--primary);
  border-radius: 4px;
  color: var(--neutral-100);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  margin: 0 auto 1rem;
  min-height: 2.25rem;
  min-width: auto;
  width: 80%;
  @include mat.elevation(1, var(--neutral-45));

  &:hover:not([disabled]),
  &:focus {
    opacity: 0.75;
    outline: dashed 1px var(--border-80);
    outline-offset: 2px;
  }

  &[disabled],
  &.disabled {
    background: var(--background-neutral);
    border-color: var(--background-neutral);
    color: var(--neutral-40) !important; // because material theme
    font-weight: 300;
    letter-spacing: initial;
    @include mat.elevation(0);
  }

  &.alternate {
    background: var(--neutral-100);
    color: var(--primary);
    border-color: var(--primary);

    &[disabled],
    &.disabled {
      background: var(--background-neutral);
      border-color: var(--background-neutral);
    }
  }

  &.warn {
    background: var(--warn-compliant);
    color: var(--primary-contrast);
    border-color: var(--warn-compliant);

    &[disabled],
    &.disabled {
      background: var(--background-neutral);
      border-color: var(--background-neutral);
    }
  }
}

a.button:hover {
  text-decoration: none;
}

button.mat-mdc-menu-item {
  align-items: center;
  border-radius: 0;
  display: flex;
  margin: 0;
  box-shadow: none;

  &:hover:not([disabled]),
  &:focus {
    outline: none;
  }

  img {
    display: block;
    margin-right: 0.5rem;
    width: 1.25rem;
  }
}

button.mdc-switch { box-shadow: none; }
button.mdc-switch + .mdc-label { margin: 0;}
.mat-mdc-slide-toggle .mat-internal-form-field { gap: 1ch; }

.mat-radio-group,
.mat-radio-group .mat-radio-button,
.mat-checkbox {
  display: block;
  label { margin: 0;}
}
.mdc-radio + .mdc-label { margin: 0; }
.mdc-checkbox + .mdc-label { margin: 0; }

fieldset {
  border: none;
  display: block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: min-content;
  margin-bottom: 1rem;

  legend {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    margin: 0;
  }
}

.card {
  background: var(--neutral-100);
  @include mat.elevation(1, var(--neutral-60)-grey);
  margin-bottom: 1rem;
  padding: 0;
  border-radius: 4px;

  .content {
    padding: 0.75rem 1rem 0.75rem;

    p {
      margin-top: 1rem;
    }
  }

  .header {
    padding: .5rem 1rem;
    background: var(--background-neutral)
    h2 {
      margin: 0;
      font-size: 1.125rem;
    }
    // &.requested, &.Requested { background: map.get($palettes, map.get(primary, 50)) }
    // &.approved, &.Approved { background: map.get($palettes, map.get(accent, 50)) }
    // &.denied, &.Denied { background: map.get(tertiary, 50) }
    // &.canceled, &.Canceled { background: var(--background-neutral); }
  }

  .header.header-center {
    text-align: center;
  }

  .header.success {
    background: var(--background-accent);
    text-align: center;

    h2 {
      font-size: 1.5rem;
      color: var(--accent-compliant);
    }
  }

  .header.pending {
    background: var(--background-neutral);
    text-align: center;

    h2 {
      font-size: 1.5rem;
      color: var(--primary);
    }
  }

  .header.denied {
    background: var(--background-warn);
    text-align: center;

    h2 {
      font-size: 1.5rem;
      color: var(--warn-compliant);
    }
  }
}

table {
  background: var(--neutral-100);
  border-collapse: collapse;
  width: 100%;

  thead,
  th {
    color: var(--neutral-40);
    font-size: 0.75rem;
    font-weight: 300;
    text-align: left;
    letter-spacing: 0.0625rem;
  }

  td {
    font-weight: 400;
    font-size: 0.875rem;
  }

  th,
  td {
    padding: 0.75rem 0.5rem;
  }

  tr {
    border-bottom: solid 1px var(--border-90);
  }

  tbody tr:nth-of-type(odd) {
    background: var(--background-accent);
  }

  .mat-sort-header-button {
    box-shadow: none;
    padding: 0;
    height: auto;
    margin: 0;
    white-space: pre-wrap;
  }
}

.comments {
  font-size: 1rem;
  color: $medium;
  font-weight: normal;

  .icon {
    height: 1rem;
    width: 1rem;
  }
}

.toggle-btn {
  button {
    align-items: center;
    background: inherit;
    border: none;
    box-shadow: none;
    display: flex;
    color: inherit;
    justify-content: center;
    width: 1.5rem;
  }
}

.info {
  background: map.get(map.get(theme.$palettes, "primary"), 98);
  border: solid 1px map.get(map.get(theme.$palettes, "primary"), 40);
  padding: 1rem;
  border-radius: 4px;
}

.mat-datepicker-toggle {
  width: 2rem;
}

.date-container,
.address-search-bar-container,
.optional-address-container {
  align-items: center;
  display: flex;
  padding: 0 1rem 0 0;
  overflow: scroll;

  button[matsuffix],
  .mat-mdc-icon-button {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    line-height: 0;
    margin: 0 0 0 0.75rem;
    width: 2rem;
    min-width: 0;

    img {
      width: 1.75rem;
    }
  }

  button.alternate {
    border: none;
    box-shadow: none;
  }
}

.address-search-bar-container {
  padding: 0px;
}

button.mat-mdc-paginator-navigation-previous.mat-mdc-icon-button,
button.mat-mdc-paginator-navigation-next.mat-mdc-icon-button {
  color: var(--neutral-100);
  height: 2.5rem;
  width: 2.5rem;
  margin: 5px 0 5px 5px;
}

.mat-calendar-arrow {
  border-top-color: var(--neutral-100);
}

hr {
  background: var(--border-80);
  border: none;
  height: 1px;
}

a.request-ride {
  align-items: center;
  background: var(--neutral-100);
  border-radius: 4px;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3.5rem;
  @include mat.elevation(2, var(--primary));
  right: 1rem;
  padding: 0 0.6rem;
  position: absolute;
  text-align: center;
  top: 1.25rem;
  width: 7.5rem;
  z-index: 100;

  span {
    display: block;
    flex-basis: 5rem;
    flex-shrink: 0;
  }

  img {
    height: 4rem;
    left: -0.1rem;
    position: relative;
  }
}

.mat-checkbox-layout {
  white-space: normal !important; // Material override to allow checkbox label to wrap
}

mat-paginator {
  .mat-paginator-navigation-next,
  .mat-paginator-navigation-previous {
    width: fit-content;
    margin: 4px;
  }
}

.snackbar-error.mat-mdc-snack-bar-container {
  max-width: 90vw;
  background-color: var(--background-warn);
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action {
  margin: 0;
}

// these are for the notification service
.snackbar-success-notification.mat-mdc-snack-bar-container {
  background-color: var(--primary);
  color: var(--neutral-100);
  .mat-mdc-snackbar-surface {
    background-color: var(--primary);
  }
}

.snackbar-error-notification.mat-mdc-snack-bar-container {
  background-color: var(--warn-compliant);
  color: var(--neutral-100);
  .mat-mdc-snackbar-surface {
    background-color: var(--warn-compliant);
  }
}
.snackbar-warning-notification.mat-mdc-snack-bar-container {
  background-color: var(--warn-compliant);
  color: var(--neutral-100);
  .mat-mdc-snackbar-surface {
    background-color: var(--warn-compliant);
  }
}

.screen-reader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/** Handle google maps **/
.map-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.map-container > div,
.map-container iframe,
.map-container object,
.map-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/** End Handle google maps **/
