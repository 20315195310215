html {
  --primary: #263369;
  --primary-contrast: #ffffff;
  --accent-official: #8cc63f;
  --accent-compliant: #597e26;
  --warn-official: #f77a02;
  --warn-compliant: #bb5b02;

  --gold: gold;

  --links: #4f63bf;

  --neutral-0: hsl(0, 0%, 0%);
  --neutral-10: hsl(0, 0%, 10%);
  --neutral-40: hsl(0, 0%, 40%);
  --neutral-45: hsl(0, 0%, 45%);
  --neutral-50: hsl(0, 0%, 50%);
  --neutral-60: hsl(0, 0%, 60%);
  --neutral-70: hsl(0, 0%, 70%);
  --neutral-80: hsl(0, 0%, 80%);
  --neutral-90: hsl(0, 0%, 90%);
  --neutral-100: hsl(0, 0%, 100%);

  --overlay: hsla(0, 0%, 0%, 0.5);
  --shadow-color: hsla(0, 0%, 100%, 0.5);
  --shadow: 0px 2px 1px -1px rgb(204 208 204 / 20%),
            0px 1px 1px 0px rgb(204 208 204 / 14%),
            0px 1px 3px 0px rgb(204 208 204 / 12%);

  --background: #fafafa;
  --background-neutral: hsl(0, 15%, 96%);
  --background-warn: #fff6ed;
  --background-accent: #f6fded;

  --border-80: hsl(120, 4%, 80%);
  --border-90: hsl(120, 4%, 90%);
}
