//  Breakpoints
$small: 300px;
$medium: 600px;
$large: 840px;
$extra-large: 1280px;

//  Mixins

@mixin ride-request-card {
  form {
    margin-top: 1rem;
  }
  .btn-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
    flex-wrap: wrap;
  }
  button,
  a.button {
    margin: 0 0 0 0.5rem;
    min-width: 6rem;
    padding: 0.0625rem 0.5rem;
    width: auto;
  }
  .alternate {
    border: none;
    box-shadow: none;
  }
}
